<template>
  <div class="page-wrapper">
    <h1>Gestion des articles/annonces</h1>

    <h2>Liste des annonces</h2>
    <form @submit.prevent>
      <input v-model="search" type="text" placeholder="Rechercher une annonces" @keyup="filterAlertsList" />
    </form>

    <v-table
      v-if="filteredAlertsList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="filteredAlertsList"
    >
      <thead slot="head">
        <v-th sort-key="name">Question</v-th>
        <v-th sort-key="category">Catégorie</v-th>
        <v-th sort-key="shouldSendNotification">Notification</v-th>
        <v-th sort-key="notificationSentTimestamp">Envoyé le</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.category }}</td>
          <td>{{ row.shouldSendNotification }}</td>
          <td>{{ row.notificationSentTimestamp }}</td>
          <td>
            <button @click.prevent="editAlert($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteAlert($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <v-table
      v-else-if="alertsList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="alertsList"
    >
      <thead slot="head">
        <v-th sort-key="name">Question</v-th>
        <v-th sort-key="category">Catégorie</v-th>
        <v-th sort-key="shouldSendNotification">Notification</v-th>
        <v-th sort-key="notificationSentTimestamp">Envoyé le</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.category }}</td>
          <td>{{ row.shouldSendNotification }}</td>
          <td>{{ row.notificationSentTimestamp }}</td>
          <td>
            <button @click.prevent="editAlert($event, row)">🖊</button>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteAlert($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <h2>Ajout/Modification</h2>
    <!-- Auth UI -->
    <form @submit="saveAlert($event)">
      <div>
        <label for="category">Catégorie</label>
        <select id="category" v-model="alert.category" name="category" required>
          <option value="A" selected>A</option>
          <option value="B">B</option>
          <option value="C">C</option>
          <option value="D">D</option>
        </select>
      </div>

      <div>
        <label for="name">Annonce</label>
        <textarea id="name" v-model="alert.name" required></textarea>
      </div>

      <div>
        <label for="shouldSendNotification">Envoyer une notification</label>
        <input id="shouldSendNotification" v-model="alert.shouldSendNotification" type="checkbox" value="1" />
      </div>

      <div>
        <button type="submit" class="bt" :class="{ 'is-loading': isLoading }">
          <div class="bt-text">
            <p v-if="mode === 'create'">Ajouter</p>
            <p v-else>Modifer</p>
          </div>
          <SmallLoader />
        </button>
      </div>
    </form>

    <h2>Liste des articles</h2>
    <form @submit.prevent>
      <input v-model="searchArticle" type="text" placeholder="Rechercher un article" @keyup="filterArticlesList" />

      <div>
        <router-link :to="{ name: 'admin/create/articles' }" class="bt" :class="{ 'is-loading': isLoading }">
          <div class="bt-text">
            <p>Ajouter</p>
          </div>
          <SmallLoader />
        </router-link>
      </div>
    </form>

    <v-table
      v-if="filteredArticlesList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="filteredArticlesList"
    >
      <thead slot="head">
        <v-th sort-key="name"></v-th>
        <v-th sort-key="isPublished">État</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.isPublished ? 'Publié' : 'Non publié' }}</td>
          <td>
            <router-link :to="{ name: 'admin/create/articles', params: { articleId: row.id } }">🖊</router-link>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteArticle($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>

    <v-table
      v-else-if="articlesList"
      class="table table-striped table-bordered"
      cellspacing="0"
      cellpadding="0"
      :data="articlesList"
    >
      <thead slot="head">
        <v-th sort-key="name">Nom de l'article</v-th>
        <v-th sort-key="isPublished">État</v-th>
        <th>&nbsp;</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.name }}</td>
          <td>{{ row.isPublished ? 'Publié' : 'Non publié' }}</td>
          <td>
            <router-link :to="{ name: 'admin/create/articles', params: { articleId: row.id } }">🖊</router-link>
            <button v-if="row.role !== 'admin'" @click.prevent="deleteArticle($event, row.id)">🗑</button>
          </td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AlertsDB from '@/firebase/alerts-db'
import ArticlesDB from '@/firebase/articles-db'
import SmallLoader from '@/components/particles/SmallLoader'

export default {
  components: {
    SmallLoader
  },
  data: () => ({
    isLoading: false,
    alert: {
      shouldSendNotification: false,
      notificationSent: false,
      notificationSentTimestamp: null
    },
    alertsList: null,
    filteredAlertsList: null,
    articlesList: null,
    filteredArticlesList: null,
    mode: 'create',
    search: '',
    searchArticle: ''
  }),
  computed: {
    ...mapState('authentication', ['user'])
  },
  mounted() {
    this.getAlertsList()
    this.getArticlesList()
  },
  methods: {
    async saveAlert(e) {
      e.preventDefault()

      this.isLoading = true

      const alert = { ...this.alert }
      const alertsDB = new AlertsDB()
      console.log({ alert, mode: this.mode })

      if (this.mode === 'create') {
        await alertsDB.create(alert)
      } else {
        await alertsDB.update(alert)
      }

      this.getAlertsList()

      this.alert = {}
      this.isLoading = false
      this.mode = 'create'
    },
    async editAlert(e, alert) {
      this.isLoading = true
      const alertsDB = new AlertsDB()
      this.alert = await alertsDB.read(alert.id)
      this.mode = 'update'
      this.isLoading = false
    },
    async deleteAlert(e, alertId) {
      this.isLoading = true

      const userChoice = await this.$swal({
        title: 'Supprimer',
        text: `Confirmer la suppression.`,
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non'
      })

      if (!userChoice.isConfirmed) {
        this.isLoading = false
        return
      }

      const alertsDB = new AlertsDB()
      await alertsDB.delete(alertId)
      this.getAlertsList()
      this.isLoading = false
    },
    async getAlertsList() {
      this.isLoading = true
      const alertsDB = new AlertsDB()
      this.alertsList = await alertsDB.readAll()
      this.isLoading = false
    },
    filterAlertsList() {
      if (!this.alertsList) {
        return
      }

      if (this.search && this.search.length >= 3) {
        this.filteredAlertsList = this.alertsList.filter(i => i.name.includes(this.search))
      } else {
        this.filteredAlertsList = null
      }
    },
    async getArticlesList() {
      this.isLoading = true
      const articlesDb = new ArticlesDB()
      this.articlesList = await articlesDb.readAll()
      this.isLoading = false
    },
    filterArticlesList() {
      if (!this.articlesList) {
        return
      }

      if (this.searchArticle && this.searchArticle.length >= 3) {
        this.filteredArticlesList = this.articlesList.filter(i => i.name.includes(this.searchArticle))
      } else {
        this.filteredArticlesList = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
</style>
